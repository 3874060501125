<template>
  <div>
    <ClientOnly>
      <div v-if="dataJSON">
        <LottieAnimation
          ref="animation"
          :loop="data.loop"
          :autoPlay="data.autoplay"
          :animationData="dataJSON"
          @loop-complete="loopComplete"
          @complete="complete"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<script setup>
import { LottieAnimation } from 'lottie-web-vue'

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

let dataJSON

if (props.data.media) {
  const media = await useFetch(props.data.media.url)
  dataJSON = media.data.value
} else {
  dataJSON = props.data.dataJSON
}

const animation = ref(null)

const loopComplete = () => {
  // console.log('loopComplete')
}

const complete = () => {
  // console.log('complete')
}

const { stop } = useIntersectionObserver(animation, ([{ isIntersecting }], _observerElement) => {
  if (isIntersecting && animation.value) {
    animation.value.play()
  } else {
    animation.value.pause()
  }
})

onMounted(() => {
  if (animation.value && props.data.autoplay) {
    animation.value.pause()
  }
})

onUnmounted(() => {
  if (animation.value) {
    animation.value.destroy()
    stop()
  }
})
</script>
